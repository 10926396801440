<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">Match the airborne particle with its description.</p>

      <p class="mb-2" v-for="letter in letterOptions" :key="letter.inputName">
        {{ letter.text }}
        <v-select
          v-model="inputs[letter.inputName]"
          class="mt-1 mb-5 pl-2"
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI1LC_Q4S2_Q12',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      options: [
        {text: 'Tiny droplets of liquid suspended in air', value: 'a'},
        {text: 'Colloidal suspension of solid or liquid particles in air', value: 'b'},
        {text: 'Solid particles suspended in air', value: 'c'},
        {text: 'Ultrafine homogenous particles ranging in size from 1 to 100 nm', value: 'd'},
        {text: 'Mixture of dry particles and droplets of liquid', value: 'e'},
      ],
      letterOptions: [
        {text: 'a) Mists', inputName: 'input1'},
        {text: 'b) Fumes', inputName: 'input2'},
        {text: 'c) Dusts', inputName: 'input3'},
        {text: 'd) Smoke', inputName: 'input4'},
        {text: 'e) Nanoparticles', inputName: 'input5'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
